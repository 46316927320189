// The pagination of the blog list is all handled by just Array.slice
// Since this is a static website

import React, { useState, useEffect, useRef } from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import styled, { css } from "styled-components";
import { flattenArr } from "../components/helpers";
import ReactPlayer from "react-player";
import { graphql } from "gatsby";
import {
  mainWhite,
  mainRed,
  navyBlue,
  transHover,
  aku,
  innerWidth,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import { scaleIn } from "../components/common/animations";
import Arrow from "../images/svg/right-arrow.svg";
import VideoModal from "../components/video-modal";
import { getVimeoId } from "../components/common/functions";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .description {
    margin-top: 30px;
    max-width: 1000px;
    @media ${screen.medium} {
      max-width: 800px;
    }
    @media ${screen.large} {
      max-width: 1000px;
    }

    p {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1rem;
      margin-top: 16px;
      @media ${screen.small} {
        font-size: 1.1rem;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    strong {
      font-family: ${gothamBold};
    }
  }

  .filter-section {
    margin-top: 40px;
    @media ${screen.small} {
      margin-top: 60px;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${gothamBold};
      font-size: 1.3rem;
    }

    &__filter-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 1000px;
      margin-top: 20px;

      li {
        font-family: ${gotham};
        font-size: 1.05em;
        margin-right: 10px;
        margin-bottom: 15px;
        @media ${screen.small} {
          font-size: 1.25rem;
          margin-right: 22px;
          margin-bottom: 20px;
        }
        @media ${screen.medium} {
          font-size: 1.42rem;
          margin-right: 20px;
          margin-bottom: 18px;
        }

        .category {
          color: ${navyBlue};
          cursor: pointer;
          transition: ${transHover};

          &:hover {
            color: ${mainRed};
          }

          &--active {
            color: ${mainRed};
          }
        }

        .slash {
          color: ${navyBlue};
          padding-left: 5px;
          @media ${screen.small} {
            padding-left: 15px;
          }
        }
        &:last-child {
          .slash {
            visibility: hidden;
          }
        }
      }
    }
  }

  .date-section {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    margin-bottom: 22px;
    @media ${screen.small} {
      margin-top: 40px;
      margin-bottom: 0;
    }

    &__page-guide {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1rem;
      visibility: hidden;
      align-self: center;
      display: none;
      @media ${screen.small} {
        display: block;
      }
      @media ${screen.large} {
        visibility: visible;
      }
    }

    &__sort {
      display: flex;
      align-items: center;

      p {
        color: ${navyBlue};
        font-family: ${gotham};
        font-size: 0.95rem;
        margin-right: 16px;
        @media ${screen.small} {
          font-size: 1.1rem;
          margin-right: 20px;
        }
      }

      .select {
        border: 1px ${navyBlue} solid;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 14px;
        position: relative;
        font-size: 0.95rem;
        @media ${screen.small} {
          font-size: 1rem;
        }

        span {
          color: ${navyBlue};
          font-family: ${gotham};
        }

        svg {
          width: 12px;
          margin-left: 10px;
          transition: ${transHover};
        }

        .options {
          position: absolute;
          border-left: 1px ${navyBlue} solid;
          border-right: 1px ${navyBlue} solid;
          border-bottom: 1px ${navyBlue} solid;
          top: 39px;
          background: ${mainWhite};
          z-index: 5;
          width: calc(100% + 2px);
          padding: 10px 14px;
          margin-left: -15px;

          span {
            color: ${navyBlue};
            display: block;
            padding: 7px 0;
            transition: ${transHover};
            &:hover {
              color: ${mainRed};
            }
          }
        }
      }
    }
  }

  .projects-container {
    margin-top: 5px;

    &__video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;

      .video-item {
        height: 200px;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;
        animation: ${(props) =>
          props.scaleIn
            ? css`
                ${scaleIn} 0.15s ease-in-out
              `
            : `none`};
        @media ${screen.small} {
          width: 49%;
          height: 230px;
        }
        @media ${screen.medium} {
          width: 32.5%;
          height: 240px;
        }
        @media ${screen.large} {
          height: 270px;
        }
        @media ${screen.xLarge} {
          margin: 10px 0;
          height: 335px;
        }

        &__texts {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          transition: ${transHover};
          padding-top: 20px;
          padding-left: 20px;
          @media ${screen.withCursor} {
            &:hover {
              background: rgba(200, 29, 41, 0.9);
            }
          }

          .video-title {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.2rem;
            position: relative;
            max-width: 440px;
            padding-right: 20px;
            @media ${screen.small} {
              font-size: 1.3rem;
            }
            @media ${screen.xLarge} {
              padding-right: 0;
            }

            &::after {
              content: "";
              display: block;
              width: 45px;
              height: 3px;
              margin: 18px 0;
              background: ${mainWhite};
            }
          }

          .video-subtitle {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 0.92rem;
            display: inline-block;
            margin-right: 5px;
            @media ${screen.small} {
              font-size: 1rem;
            }

            &:last-child {
              .video-subtitle--comma {
                display: none;
              }
            }
          }
        }

        &__video {
          width: 100% !important;
          height: 100% !important;
          pointer-events: none;
        }
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }

      &:after {
        content: " ";
        visibility: hidden;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
      }
    }

    &__paginate {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      max-width: 1000px;

      .box {
        display: block;
        cursor: pointer;
        width: 38px;
        height: 40px;
        border: 1px ${navyBlue} solid;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: ${transHover};

        &:hover {
          border: 1px ${mainRed} solid;
        }

        &:hover .body {
          fill: ${mainRed};
          transition: ${transHover};
        }

        &:hover .page-num {
          color: ${mainRed};
          transition: ${transHover};
        }

        .page-num {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.1rem;
        }

        &--active {
          background: ${mainRed};
          border: 1px ${mainRed} solid;
          pointer-events: none;
          &:hover {
            background: ${mainWhite};
            border: 1px ${navyBlue} solid;
          }
          &:hover .page-num {
            color: ${navyBlue};
          }
          .page-num {
            color: ${mainWhite};
          }
        }
      }
    }

    &__page-guide {
      color: ${navyBlue};
      font-family: ${gotham};
      align-self: center;
      font-size: 0.85rem;
      margin-top: 5px;
      @media ${screen.large} {
        display: none;
      }
    }
  }
`;

const PortfolioPage = ({ data }) => {
  // get all categories from prismic project list
  const getCategories = data.projects.edges.map((item) => {
    return item.node.data.categories.map(
      (each) => each.category.document && each.category.document.data.title.text
    );
  });

  // array of categories to use for filtering
  const allCategories = flattenArr(getCategories);

  // basic hack to trigger the video modal component to watch location hash changes
  const [triggerLightboxComponent, setTriggerLightboxComponent] = useState(
    false
  );

  // number length of how many news will be displayed per page
  // desktop and tablet/mobile have different length
  // 4 for tablet/mobile
  // 9 for desktop
  const [num, setNum] = useState(
    typeof window !== "undefined" && window.innerWidth < 1160 ? 4 : 9
  );

  // starter page length
  const [eachPageLength, setPageLength] = useState(9);
  // starter page length from
  const [paginateFrom, setPaginateFrom] = useState(0);
  // starter page length to
  const [paginateTo, setPaginateTo] = useState(num);
  // current page selected
  const [currentPage, setCurrentPage] = useState(1);
  // all video projects from Prismic
  const [projectList, setProjectList] = useState(data.projects.edges);
  // selected category
  const [selectedCategory, setSelectedCategory] = useState("All");
  // filter date state
  const [oldToNewFilter, setOldToNewFilter] = useState(true);
  // date sorter dropdown state
  const [sortDateOpen, setSortDate] = useState(false);
  // animate project list only if user start filtering projects
  const [triggerAnimation, setAnimation] = useState(false);

  const list = useRef(null);

  // count the child element of the project list
  useEffect(() => {
    setPageLength(list.current.childElementCount);
  });

  // screen resize listener
  useEffect(() => {
    const CheckBrowserWidth = () => {
      if (window.innerWidth > 1160) {
        setNum(9);
      } else {
        setNum(4);
      }
    };
    window.addEventListener("resize", CheckBrowserWidth);

    return () => {
      window.removeEventListener("resize", CheckBrowserWidth);
    };
  }, [num]);

  // watcher when user select a category
  useEffect(() => {
    if (selectedCategory === `All`) {
      setProjectList(data.projects.edges);
    } else {
      const filtered = data.projects.edges.filter((project) => {
        return project.node.data.categories.some(
          (cat) =>
            cat.category.document &&
            cat.category.document.data.title.text === selectedCategory
        );
      });
      setProjectList(filtered);
    }
    setCurrentPage(1);
  }, [selectedCategory]);

  // Prismic content
  const pageTitle = data.content.data.page
    ? data.content.data.page.text
    : `Portfolio`;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Portfolio`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Portfolio`;
  const description = data.content.data.description.html;

  const viewVideo = (videoId) => {
    window.history.replaceState(null, null, `#${videoId}`);
    setTriggerLightboxComponent(!triggerLightboxComponent);
  };

  // better structure the array of each video object
  // filter the projects by date if user toggle to "old to new" or "new to old" dropdown
  // sorting the date by just swapping over the exact same array just with the different date order
  const allProjects = oldToNewFilter
    ? projectList
        .map((project) => {
          return {
            id: project.node.id,
            title: project.node.data.title.text,
            video_url: project.node.data.video_url,
            category: project.node.data.categories.map(
              (item) =>
                item.category.document && item.category.document.data.title.text
            ),
            date: project.node.data.date
              ? Number(project.node.data.date.split("-").join(""))
              : Number(
                  project.node.first_publication_date
                    .slice(0, 10)
                    .split("-")
                    .join("")
                ),
          };
        })
        .sort((a, b) => b.date - a.date)
    : projectList
        .map((project) => {
          return {
            id: project.node.id,
            title: project.node.data.title.text,
            video_url: project.node.data.video_url,
            category: project.node.data.categories.map(
              (item) =>
                item.category.document && item.category.document.data.title.text
            ),
            date: project.node.data.date
              ? Number(project.node.data.date.split("-").join(""))
              : Number(
                  project.node.first_publication_date
                    .slice(0, 10)
                    .split("-")
                    .join("")
                ),
          };
        })
        .sort((a, b) => a.date - b.date);

  // get the pagination length of all the blogs
  const projectsPage = new Array(Math.ceil(allProjects.length / num)).fill(" ");

  // blogs to render by slice the array from its index
  const renderProjects = allProjects.slice(paginateFrom, paginateTo);

  // hide/show date sorter dropdown
  const toggleSortDate = () => {
    setSortDate(!sortDateOpen);
  };

  // sort blog list new to old
  const sortNewToOld = () => {
    setOldToNewFilter(true);
    toggleSortDate();
    if (!triggerAnimation) {
      setAnimation(true);
    }
  };

  // sort blog list old to new
  const sortOldToNew = () => {
    setOldToNewFilter(false);
    toggleSortDate();
    if (!triggerAnimation) {
      setAnimation(true);
    }
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper scaleIn={triggerAnimation}>
        <h2 className="heading">
          <Arrow />
          {pageTitle}
        </h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <div className="filter-section">
          <h3 className="filter-section__heading" id="portfolios">
            The type of work we do
          </h3>
          <ul className="filter-section__filter-list">
            <li>
              <span
                className={
                  selectedCategory === `All`
                    ? `category category--active`
                    : `category`
                }
                onClick={() => {
                  if (selectedCategory !== `All`) {
                    setSelectedCategory(`All`);
                    setPaginateFrom(0);
                    setPaginateTo(num);

                    if (!triggerAnimation) {
                      setAnimation(true);
                    }
                  }
                }}
              >
                All
              </span>{" "}
              <span className="slash"> / </span>
            </li>
            {allCategories.map((category) => (
              <li key={category}>
                <span
                  className={
                    selectedCategory === category
                      ? `category category--active`
                      : `category`
                  }
                  onClick={() => {
                    if (selectedCategory !== category) {
                      setSelectedCategory(category);
                      setPaginateFrom(0);
                      setPaginateTo(num);

                      if (!triggerAnimation) {
                        setAnimation(true);
                      }
                    }
                  }}
                >
                  {category}
                </span>
                <span className="slash"> / </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="date-section">
          <p className="date-section__page-guide">
            Showing {paginateFrom + 1} - {paginateFrom + eachPageLength} of{" "}
            {allProjects.length} Projects
          </p>
          <div className="date-section__sort">
            <p>Sort by: </p>
            <div className="select" onClick={toggleSortDate}>
              <span>Date, {oldToNewFilter ? `New to Old` : `Old to New`}</span>
              <Arrow
                style={{
                  transform: sortDateOpen ? `rotate(89deg)` : `rotate(0deg)`,
                }}
              />
              {sortDateOpen && (
                <div className="options">
                  <span onClick={sortNewToOld}>Date, New to Old</span>
                  <span onClick={sortOldToNew}>Date, Old to New</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="projects-container">
          <ul className="projects-container__video-list" ref={list}>
            {renderProjects.map((item) => (
              <li
                className="video-item"
                onClick={() => viewVideo(getVimeoId(item.video_url))}
                key={item.id}
              >
                <div className="video-item__texts">
                  <h2 className="video-title">{item.title}</h2>
                  {item.category.map((cat, i) => (
                    <p className="video-subtitle" key={cat || i}>
                      {cat}
                      <span className="video-subtitle--comma">,</span>
                    </p>
                  ))}
                </div>
                <ReactPlayer
                  className="video-item__video"
                  url={item.video_url}
                  light={`https://vumbnail.com/${getVimeoId(
                    item.video_url
                  )}.jpg`}
                />
              </li>
            ))}
          </ul>
          <div className="projects-container__paginate">
            {projectsPage.map((empty, i) => (
              <span
                className={currentPage === i + 1 ? `box box--active` : `box`}
                key={i + 1}
                onClick={() => {
                  setPaginateFrom(num * i);
                  window.scroll(0, 0);
                  setPaginateTo(num * (i + 1));
                  setCurrentPage(i + 1);
                  if (!triggerAnimation) {
                    setAnimation(true);
                  }
                }}
              >
                <span className="page-num">{i + 1}</span>
              </span>
            ))}
          </div>
          <p className="projects-container__page-guide">
            Showing {paginateFrom + 1} - {paginateFrom + eachPageLength} of{" "}
            {allProjects.length} Projects
          </p>
        </div>
        <VideoModal triggerLightboxComponent={triggerLightboxComponent} />
      </Wrapper>
    </Layout>
  );
};

export default PortfolioPage;

export const dataQuery = graphql`
  {
    content: prismicPortfolioPage {
      data {
        title_tag
        meta_description
        description {
          html
        }
        page {
          text
        }
      }
    }
    projects: allPrismicProject {
      edges {
        node {
          first_publication_date
          id
          data {
            title {
              text
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            date
            video_url
          }
        }
      }
    }
  }
`;
